import { useEffect, useState } from "react";
import { useProducts } from "../../../hooks/useProduct";
import { Inspection } from "../../../interfaces/ReportsModels/Inspection";
import { WorkReport } from "../../../interfaces/ReportsModels/Work";
import { usePDF } from "../../../hooks/usePDF";
import {
  initReportsFilters,
  ReportsFilters,
  Reports as ReportsTS,
  ReportTypes,
} from "../../../interfaces/ReportsModels/Reports";
import useReports from "../../../hooks/useReports";
import useCustomer from "../../../hooks/useCustomers";

import ReportRow from "./ReportRow/ReportRow";
import ReportsForm from "./ReportsForm/ReportsForm";
import WorkForm from "./WorkForm/WorkForm";
import InspectionForm from "./InspectionForm/InspectionForm";
import Lists from "./Lists/Lists";

import styles from "./Reports.module.css";
import Filters from "./Filters/Filters";

export default function Reports() {
  const pdf = usePDF();
  const reports = useReports();
  const products = useProducts();
  const customers = useCustomer();
  const [form, setForm] = useState<boolean>(false);
  const [list, setList] = useState(false);
  const [data, setData] = useState<ReportsTS | null>(null);
  const [rows, setRows] = useState<Array<WorkReport | Inspection>>([]);
  const [filters, setFilters] = useState<ReportsFilters>(initReportsFilters());
  const [formSelected, setFormSelected] = useState<ReportTypes>(
    ReportTypes.ANY
  );

  // Get reports
  useEffect(() => {
    reports.list.get();
    if (!reports.data) reports.get();
    if (products.data.length <= 0) products.get();
    if (customers.data.length <= 0) customers.get();
  }, []);

  // Set rows
  useEffect(() => {
    let rows: ReportsTS[] = [];

    // Select Report type
    if (reports.data && Number(filters.type) === ReportTypes.WORK)
      rows = reports.data.work;
    if (reports.data && Number(filters.type) === ReportTypes.INSPECTION)
      rows = reports.data.inspection;

    // Apply filters
    rows = rows.filter((report) => {
      /* DATE */
      if (
        filters.date &&
        (filters.date.getFullYear() !== report.date.getFullYear() ||
          filters.date.getMonth() !== report.date.getMonth() ||
          filters.date.getDate() !== report.date.getDate())
      )
        return false;

      /* USER */
      if (filters.user && filters.user === report.userId) return false;

      return true;
    });

    // Save rows
    setRows(rows);
  }, [reports.data, filters]);

  // Show list
  function handleShowList() {
    setList(!list);
  }

  // View form and edit user
  function handleEdit(data: ReportsTS) {
    setData(data);
    if (Number(filters.type) === ReportTypes.WORK)
      setFormSelected(ReportTypes.WORK);
    if (Number(filters.type) === ReportTypes.INSPECTION)
      setFormSelected(ReportTypes.INSPECTION);
  }

  // delete reports
  function handleDelete(id: number) {
    if (Number(filters.type) === ReportTypes.WORK) reports.deleteWork(id);
    if (Number(filters.type) === ReportTypes.INSPECTION)
      reports.deleteInspection(id);
  }

  // Alternate form
  function handleForm() {
    setForm(!form);
    if (form) setData(null);
  }

  // Close any form
  function handleCloseSelectedForm() {
    setFormSelected(ReportTypes.ANY);
    setData(null);
    setForm(false);
  }

  // Submit work data
  function handleSubmitWork(report: WorkReport) {
    data ? reports.updateWork(report) : reports.setWork(report);
  }

  // Submit inspection data
  function handleSubmitInspection(report: Inspection) {
    data ? reports.updateInspection(report) : reports.setInspection(report);
  }

  // Generate PDF
  function handleGeneratePDF(report: ReportsTS) {
    if (Number(filters.type) === ReportTypes.INSPECTION)
      pdf.openInspectionPDF(report);
    if (Number(filters.type) === ReportTypes.WORK) pdf.openWorkPDF(report);
  }

  return (
    <div className={styles.dashboard}>
      {list && <Lists handleClose={handleShowList} />}
      {form && (
        <ReportsForm handleClose={handleForm} handleSelect={setFormSelected} />
      )}
      {formSelected === ReportTypes.WORK && (
        <WorkForm
          data={data}
          handleClose={handleCloseSelectedForm}
          handleSubmit={handleSubmitWork}
        />
      )}
      {formSelected === ReportTypes.INSPECTION && (
        <InspectionForm
          data={data}
          handleClose={handleCloseSelectedForm}
          handleSubmit={handleSubmitInspection}
        />
      )}
      <div className={styles.controls}>
        <div className={styles.left}>
          <Filters filters={filters} handleSubmit={setFilters} />
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={handleShowList}
          >
            Lists
          </button>
        </div>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={handleForm}
        >
          + New Report
        </button>
      </div>
      <div className={styles.table}>
        <div className={`${styles.row} ${styles.firstRow}`}>
          <span>Type</span>
          <span>Number</span>
          <span>Date</span>
          <span>User</span>
          <span>Actions</span>
        </div>
        <div className={styles.body}>
          {rows.length <= 0 ? (
            <tr className={styles.emptyRows}>
              <th>No Reports</th>
            </tr>
          ) : (
            rows
              .sort((a, b) => Number(b.numero) - Number(a.numero))
              .map((report: ReportsTS) => (
                <ReportRow
                  key={report.numero}
                  report={report}
                  type={filters.type}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleOpenPDF={handleGeneratePDF}
                />
              ))
          )}
        </div>
      </div>
    </div>
  );
}
