import { MyThunkAction } from "../../../interfaces/ReduxState";
import { Customers } from "../../../interfaces/Customers";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import axios from "axios";

export const SET_CUSTOMER = "SET_CUSTOMER";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

export function postCustomers(customer: Customers): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await axios.post("/customers", customer);

      dispatch({
        type: SET_CUSTOMER,
        payload: response.data,
      });
    } catch (error: any) {
      throw new Error(error?.response?.data.error || error);
    }
  };
}

export function getAllCustomers(): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await axios.get("/customers");

      dispatch({
        type: GET_CUSTOMERS,
        payload: response.data,
      });
    } catch (error: any) {
      throw new Error(error?.response?.data.error || error);
    }
  };
}

export function updateCustomer(customer: Customers): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await axios.patch("/customers", customer);

      dispatch({
        type: UPDATE_CUSTOMER,
        payload: customer,
      });
    } catch (error: any) {
      throw new Error(error?.response?.data.error || error);
    }
  };
}

export function deleteCustomer(customerId: string): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await axios.delete(`/customers/${customerId}`);

      dispatch({
        type: DELETE_CUSTOMER,
        payload: customerId,
      });
    } catch (error: any) {
      throw new Error(error?.response?.data.error || error);
    }
  };
}
