import { useEffect, useState } from "react";
import {
  Customers,
  CustomersError,
  initCustomers,
  initCustomersError,
} from "../../../../interfaces/Customers";

import Input from "../../../../components/Inputs/Input";

import styles from "./CustomersForm.module.css";

export interface Props {
  data: Customers | null;
  handleClose: () => void;
  handleSubmit: (customer: Customers) => void;
}

export default function CustomersForm({
  data,
  handleClose,
  handleSubmit,
}: Props) {
  const [customer, setCustomer] = useState<Customers>(initCustomers());
  const [error, setError] = useState<CustomersError>(initCustomersError());

  // If data is selected for editing, update product local data
  useEffect(() => {
    if (data) setCustomer(data);
  }, [data]);

  // Change product
  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    setCustomer({ ...customer, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  }

  // Sbubmit data
  function handleLocalSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (validations()) {
      handleSubmit(customer);
      handleClose();
    }
  }

  // Errors validations
  function validations() {
    let errors: CustomersError = initCustomersError();
    let value = true;

    /* NAME */
    if (customer.name === "") {
      errors.name = "This field can not be blank";
      value = false;
    }

    setError(errors);
    return value;
  }

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <header className={styles.header}>
          <h3 className={styles.headerTitle}>New customer</h3>
          <button
            className={styles.headerClose}
            type="button"
            onClick={handleClose}
          >
            X
          </button>
        </header>
        <form className={styles.form} onSubmit={handleLocalSubmit}>
          <Input
            name="name"
            label="Name"
            value={customer.name}
            error={error.name}
            handleChange={handleChange}
          />
          <Input
            name="fullName"
            label="Full name"
            value={customer.fullName}
            error={error.fullName}
            handleChange={handleChange}
          />
          <Input
            name="email"
            label="Email"
            value={customer.email}
            error={error.email}
            handleChange={handleChange}
          />
          <Input
            name="phoneNumber"
            label="Phone number"
            value={customer.phoneNumber}
            error={error.phoneNumber}
            handleChange={handleChange}
          />
          <Input
            name="billingAdress"
            label="Billing adress"
            value={customer.billingAdress}
            error={error.billingAdress}
            handleChange={handleChange}
          />
          <Input
            name="shippingAdress"
            label="Shipping adress"
            value={customer.shippingAdress}
            error={error.shippingAdress}
            handleChange={handleChange}
          />
          <button className="btn btn-success" type="submit">
            Save
          </button>
        </form>
      </div>
    </div>
  );
}
