import { useSelector } from "react-redux";
import { RootState } from "../../../../../interfaces/ReduxState";
import DataSelector from "../../../../../components/DataSelector/DataSelector";

import styles from "./ProductSelector.module.css";

export interface Props {
  selected: string;
  onClose: () => void;
  onSelect: (product: string) => void;
}

export default function ProductSelector({
  selected,
  onClose,
  onSelect,
}: Props) {
  const products = useSelector((state: RootState) => state.products.data);
  const list = useSelector((state: RootState) => state.reports.lists);

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <header className={styles.header}>
          <h3 className={styles.headerTitle}>Products</h3>
          <button
            className={styles.headerClose}
            type="button"
            onClick={onClose}
          >
            X
          </button>
        </header>
        <form className={styles.form}>
          <DataSelector
            data={products
              .map((data) => ({
                id: `${data.skuNumber} - ${data.description}`,
                label: `${data.skuNumber} - ${data.description}`,
              }))
              .concat(
                list.parts.map((part) => ({
                  id: part,
                  label: part,
                }))
              )}
            placeHolder={"Select a product"}
            selected={[selected]}
            onSelect={onSelect}
            height="500px"
            onRemove={() => onSelect("")}
          />
          <div className={styles.btnContainer}>
            <button
              className="btn btn-outline-success"
              type="button"
              onClick={onClose}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
