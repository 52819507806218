import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { Inspection } from "../../interfaces/ReportsModels/Inspection";
import { ReportLists } from "../../interfaces/ReportsModels/Lists";
import { WorkReport } from "../../interfaces/ReportsModels/Work";
import { RootState } from "../../interfaces/ReduxState";
import { Report } from "../../interfaces/ReportsModels/Reports";
import { usePDF } from "../usePDF";
import {
  deleteWorkReport,
  deleteInspectionReport,
  getLists,
  getReports,
  setWorkReport,
  setInspectionReport,
  updateWorkReport,
  updateInspectionReport,
} from "../../redux/actions/reports";
import swal from "sweetalert";
import axios from "axios";

interface UseReports {
  data: Report;
  get: () => Promise<void>;
  setWork: (data: WorkReport) => Promise<void>;
  setInspection: (data: Inspection) => Promise<void>;
  updateWork: (data: WorkReport) => Promise<void>;
  updateInspection: (data: Inspection) => Promise<void>;
  deleteWork: (id: number) => Promise<void>;
  deleteInspection: (id: number) => Promise<void>;
  list: {
    data: ReportLists;
    get: () => void;
    setItems: () => void;
    deleteItems: () => void;
  };
}

export default function useReports(): UseReports {
  const dispatch = useDispatch();
  const pdf = usePDF();
  const reportsState = useSelector((state: RootState) => state.reports);

  const sendWorkReport = async (report: WorkReport): Promise<void> => {
    try {
      // Post work report
      dispatch(openLoading());
      const response = await axios.post("/work", report);
      dispatch(closeLoading());

      // Convert data
      const work = {
        ...response.data,
        date: new Date(response.data.date),
        dateOfRepair: response.data.dateOfRepair
          ? new Date(response.data.dateOfRepair)
          : null,
      };

      // Open pdf
      pdf.openWorkPDF(work);

      // Save data
      dispatch<any>(setWorkReport(work));
    } catch (e: any) {
      dispatch(closeLoading());
      swal("Error", "Error to set work report", "error");
    }
  };

  const sendInspectionReport = async (report: Inspection): Promise<void> => {
    try {
      // Post work report
      dispatch(openLoading());
      const response = await axios.post("/inspection", report);
      dispatch(closeLoading());

      // Convert data
      const inspection = {
        ...response.data,
        date: response.data.date ? new Date(response.data.date) : null,
        yearFMCSA: response.data.yearFMCSA
          ? new Date(response.data.yearFMCSA)
          : null,
        FMCSA: response.data.FMCSA ? new Date(response.data.FMCSA) : null,
        yearBIT: response.data.yearBIT ? new Date(response.data.yearBIT) : null,
        BIT: response.data.BIT ? new Date(response.data.BIT) : null,
      };

      // Open PDF
      pdf.openInspectionPDF(inspection);

      // Save data
      dispatch<any>(setInspectionReport(inspection));
    } catch (e: any) {
      dispatch(closeLoading());
      swal("Error", "Error to set inspection report", "error");
    }
  };

  const updateWork = async (report: WorkReport): Promise<void> => {
    dispatch(openLoading());
    await dispatch<any>(updateWorkReport(report))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(closeLoading());
        swal("Error", "Error to update inspection report", "error");
      });
  };

  const updateInspection = async (report: Inspection): Promise<void> => {
    dispatch(openLoading());
    await dispatch<any>(updateInspectionReport(report))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(closeLoading());
        swal("Error", "Error to update inspection report", "error");
      });
  };

  const getReportsData = async (): Promise<void> => {
    dispatch(openLoading());
    return dispatch<any>(getReports())
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(closeLoading());
        swal("Error", "Error to get reports", "error");
      });
  };

  const deleteInspection = async (reportId: number): Promise<void> => {
    dispatch(openLoading());
    dispatch<any>(deleteInspectionReport(reportId))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(closeLoading());
        swal("Error", "Error to delete inspection report", "error");
      });
  };

  const deleteWork = async (reportId: number): Promise<void> => {
    dispatch(openLoading());
    dispatch<any>(deleteWorkReport(reportId))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(closeLoading());
        swal("Error", "Error to delete work report", "error");
      });
  };

  const setItemsList = async (): Promise<void> => {
    dispatch(openLoading());
    dispatch<any>(setItemsList())
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(closeLoading());
        swal("Error", "Error to set item", "error");
      });
  };

  const getItemsList = async (): Promise<void> => {
    dispatch(openLoading());
    dispatch<any>(getLists())
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(closeLoading());
        swal("Error", "Error to get lists", "error");
      });
  };

  const deleteItemsList = async (): Promise<void> => {
    dispatch(openLoading());
    dispatch<any>(deleteItemsList())
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(closeLoading());
        swal("Error", "Error to delete list", "error");
      });
  };

  return {
    data: reportsState.data,
    get: getReportsData,
    setWork: sendWorkReport,
    setInspection: sendInspectionReport,
    updateWork: updateWork,
    updateInspection: updateInspection,
    deleteInspection: deleteInspection,
    deleteWork: deleteWork,
    list: {
      data: reportsState.lists,
      get: getItemsList,
      setItems: setItemsList,
      deleteItems: deleteItemsList,
    },
  };
}
