export interface ReportLists {
  locations: Array<string>;
  state: Array<string>;
  services: Array<{ code: string; description: string }>;
  parts: string[];
}

export const initReportLists = (): ReportLists => ({
  locations: [],
  state: [],
  services: [],
  parts: [],
});
