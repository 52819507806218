import { ReportTypes } from "../interfaces/ReportsModels/Reports";

export default function reportNumber(
  number: number,
  type: ReportTypes
): string {
  let formatNumber: string = "";

  if (Number(type) === ReportTypes.WORK) {
    formatNumber = `WOR${`00000000${number + 1000}`.slice(-8)}`;
  } else if (Number(type) === ReportTypes.INSPECTION) {
    formatNumber = `INS${`00000000${number + 1000}`.slice(-8)}`;
  }
  return formatNumber;
}
