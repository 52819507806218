import { initReportsState, ReportsState } from "../../../interfaces/ReduxState";
import { AnyAction } from "redux";
import { LOG_OUT } from "../../actions/login";
import {
  GET_REPORTS,
  GET_REPORTS_ITEMS,
  SET_INSPECTION_REPORT,
  SET_REPORTS_ITEM,
  SET_WORK_REPORT,
  UPDATE_INSPECTION_REPORT,
  UPDATE_WORK_REPORT,
} from "../../actions/reports";

export const ReportsReducer = (
  state: ReportsState = initReportsState(),
  action: AnyAction
) => {
  switch (action.type) {
    case SET_WORK_REPORT:
      return {
        ...state,
        data: {
          ...state.data,
          work: [...(state.data?.work || []), action.payload],
        },
      };

    case SET_INSPECTION_REPORT:
      return {
        ...state,
        data: {
          ...state.data,
          inspection: [...(state.data?.inspection || []), action.payload],
        },
      };

    case UPDATE_WORK_REPORT:
      return {
        ...state,
        data: {
          ...state.data,
          work: state.data?.work.map((work) =>
            work.numero === action.payload.numero ? action.payload : work
          ),
        },
      };

    case UPDATE_INSPECTION_REPORT:
      return {
        ...state,
        data: {
          ...state.data,
          inspection: state.data?.inspection.map((inspection) =>
            inspection.numero === action.payload.numero
              ? action.payload
              : inspection
          ),
        },
      };

    case SET_REPORTS_ITEM:
      return {
        ...state,
        lists: {
          ...state.lists,
          [action.payload.listName]: [
            ...state.lists[action.payload.listName as keyof typeof state.lists],
            ...action.payload.newValues,
          ],
        },
      };

    case GET_REPORTS:
      return {
        ...state,
        data: action.payload,
      };

    case GET_REPORTS_ITEMS:
      return {
        ...state,
        lists: action.payload,
      };

    case LOG_OUT:
      return initReportsState();

    default:
      return state;
  }
};

export default ReportsReducer;
