import { Inspection } from "./Inspection";
import { WorkReport } from "./Work";

export type Report = null | {
  work: WorkReport[];
  inspection: Inspection[];
};

export type Reports = WorkReport | Inspection;

export enum ReportTypes {
  ANY,
  WORK,
  INSPECTION,
}

export interface ReportsFilters {
  type: ReportTypes;
  date: Date | null;
  user: string;
}

export const intReport = (): Report => ({
  work: [],
  inspection: [],
});

export const initReportsFilters = (): ReportsFilters => ({
  type: ReportTypes.WORK,
  date: null,
  user: "",
});
