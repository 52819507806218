export interface Inspection {
  numero: number;
  date: Date;
  userId?: string;
  userName: string;

  yearFMCSA: Date | null;
  FMCSA: Date | null;
  yearBIT: Date | null;
  BIT: Date | null;
  licenseNumber: string;
  state: string;
  location: string;
  equipment: string;
  ownerOrLessor: string;
  ok1: boolean;
  repair11: string;
  repair21: string;
  repair31: string;
  labor1: string;
  material1: string;
  ok2: boolean;
  repair12: string;
  repair22: string;
  repair32: string;
  labor2: string;
  material2: string;
  ok3: boolean;
  repair13: string;
  repair23: string;
  repair33: string;
  labor3: string;
  material3: string;
  ok4: boolean;
  repair14: string;
  repair24: string;
  repair34: string;
  labor4: string;
  material4: string;
  ok5: boolean;
  repair15: string;
  repair25: string;
  repair35: string;
  labor5: string;
  material5: string;
  ok6: boolean;
  repair16: string;
  repair26: string;
  repair36: string;
  labor6: string;
  material6: string;
  ok7: boolean;
  repair17: string;
  repair27: string;
  repair37: string;
  labor7: string;
  material7: string;
  ok8: boolean;
  repair18: string;
  repair28: string;
  repair38: string;
  labor8: string;
  material8: string;
  ok9: boolean;
  repair19: string;
  repair29: string;
  repair39: string;
  labor9: string;
  material9: string;
  ok10: boolean;
  repair110: string;
  repair210: string;
  repair310: string;
  labor10: string;
  material10: string;
  ok11: boolean;
  repair111: string;
  repair211: string;
  repair311: string;
  labor11: string;
  material11: string;
  ok12: boolean;
  repair112: string;
  repair212: string;
  repair312: string;
  labor12: string;
  material12: string;
}

export interface InspectionError {
  yearFMCSA: string;
  FMCSA: string;
  yearBIT: string;
  BIT: string;
  licenseNumber: string;
  state: string;
  location: string;
  quipment: string;
  ownerOrLessor: string;
}

export const initInspection = (userName: string): Inspection => ({
  numero: 0,
  date: new Date(),
  userName: userName || "",

  yearFMCSA: null,
  FMCSA: null,
  yearBIT: null,
  BIT: null,
  licenseNumber: "",
  state: "",
  location: "",
  equipment: "",
  ownerOrLessor: "",
  ok1: false,
  repair11: "",
  repair21: "",
  repair31: "",
  labor1: "",
  material1: "",
  ok2: false,
  repair12: "",
  repair22: "",
  repair32: "",
  labor2: "",
  material2: "",
  ok3: false,
  repair13: "",
  repair23: "",
  repair33: "",
  labor3: "",
  material3: "",
  ok4: false,
  repair14: "",
  repair24: "",
  repair34: "",
  labor4: "",
  material4: "",
  ok5: false,
  repair15: "",
  repair25: "",
  repair35: "",
  labor5: "",
  material5: "",
  ok6: false,
  repair16: "",
  repair26: "",
  repair36: "",
  labor6: "",
  material6: "",
  ok7: false,
  repair17: "",
  repair27: "",
  repair37: "",
  labor7: "",
  material7: "",
  ok8: false,
  repair18: "",
  repair28: "",
  repair38: "",
  labor8: "",
  material8: "",
  ok9: false,
  repair19: "",
  repair29: "",
  repair39: "",
  labor9: "",
  material9: "",
  ok10: false,
  repair110: "",
  repair210: "",
  repair310: "",
  labor10: "",
  material10: "",
  ok11: false,
  repair111: "",
  repair211: "",
  repair311: "",
  labor11: "",
  material11: "",
  ok12: false,
  repair112: "",
  repair212: "",
  repair312: "",
  labor12: "",
  material12: "",
});

export const initInspectionError = (): InspectionError => ({
  yearFMCSA: "",
  FMCSA: "",
  yearBIT: "",
  BIT: "",
  licenseNumber: "",
  state: "",
  location: "",
  quipment: "",
  ownerOrLessor: "",
});

export const getFMCSADate = (date: Date) => {
  const newDate = new Date(date.toISOString());
  newDate.setFullYear(newDate.getFullYear() + 1);
  return newDate;
};

export const getBITDate = (date: Date) => {
  const newDate = new Date(date.toISOString());
  newDate.setDate(newDate.getDate() + 90);
  return newDate;
};
