import { AnyAction } from "redux";
import { LOG_OUT } from "../../actions/login";
import {
  CustomersState,
  initCustomersState,
} from "../../../interfaces/ReduxState";
import {
  DELETE_CUSTOMER,
  GET_CUSTOMERS,
  SET_CUSTOMER,
  UPDATE_CUSTOMER,
} from "../../actions/customers";

const initialState: CustomersState = initCustomersState();

const customersReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_CUSTOMER:
      return [...state, action.payload];

    case GET_CUSTOMERS:
      return action.payload;

    case UPDATE_CUSTOMER:
      return state.map((customer) =>
        customer.id === action.payload.id ? action.payload : customer
      );

    case DELETE_CUSTOMER:
      return state.filter((customer) => customer.id !== action.payload);

    case LOG_OUT:
      return initCustomersState();

    default:
      return state;
  }
};

export default customersReducer;
