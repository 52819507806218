import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { WorkReport } from "../../interfaces/ReportsModels/Work";
import dateFormat from "../../scripts/dateFormat";
import reportNumber from "../../scripts/reportNumber";
import { ReportTypes } from "../../interfaces/ReportsModels/Reports";

const styles = StyleSheet.create({
  page: {
    padding: "30px",
    fontSize: "10px",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: "calc(100% - 50px)",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "5px",
    fontSize: "10px",
    border: "1px solid grey",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "20px",
    fontSize: "25px",
  },
  data: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
  },
  dataRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid grey",
    marginBottom: "5px",
    padding: "5px",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "auto",
    marginTop: "10px",
    fontSize: "10px",
    borderLeft: "1px solid grey",
    borderRight: "1px solid grey",
    borderTop: "1px solid grey",
  },
  tablaRows: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderBottom: "1px solid grey",
  },
  rowCode: {
    width: "70px",
    padding: "5px",
    borderRight: "1px solid grey",
  },
  rowDescription: {
    width: "140px",
    padding: "5px",
    borderRight: "1px solid grey",
  },
  rowLabor: {
    width: "80px",
    padding: "5px",
    borderRight: "1px solid grey",
  },
  rowPart: {
    width: "140px",
    padding: "5px",
    borderRight: "1px solid grey",
  },
  rowQuantity: {
    padding: "5px",
    width: "45px",
    borderRight: "1px solid grey",
  },
  rowTotal: {
    padding: "5px",
    width: "45px",
  },
  tiresRow: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "5px",
    borderBottom: "1px solid grey",
  },
  tiresTitle: {
    width: "100%",
    textAlign: "center",
  },
  tireCheck: {
    display: "flex",
    flexDirection: "row",
  },
  check: {
    width: "15px",
    height: "15px",
    border: "1px solid grey",
    marginRight: "5px",
  },
  checked: {
    width: "15px",
    height: "15px",
    border: "1px solid grey",
    marginRight: "5px",
    backgroundColor: "grey",
  },
});

interface Props {
  report: WorkReport;
}

// Create Document Component
export default function WorkPDF({ report }: Props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>
            Visegu Maintenance Service (510)719-1444 percyruiz@visegu.com
          </Text>
        </View>

        <View style={styles.title}>
          <Text>Work order</Text>
          <Text style={{ fontSize: "15px" }}>
            #{reportNumber(report.numero, ReportTypes.WORK)}
          </Text>
        </View>

        <View style={styles.data}>
          <View style={styles.dataRow}>
            <Text>Customer: {report.customer}</Text>
            <Text>Location: {report.location}</Text>
            <Text>Time to start services: {report.timeToStartServices}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text>Equipment: {report.equipment}</Text>
            <Text>
              Date to repair:{" "}
              {report.dateOfRepair ? dateFormat(report.dateOfRepair) : ""}
            </Text>
            <Text>Time finish service: {report.timeFinishService}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={{ width: "50%" }}>
              Lincese Plate: {report.licensePlate}
            </Text>
            <Text style={{ width: "50%" }}>PO: {report.PO}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={{ width: "50%" }}>VIN: {report.VIN}</Text>
            <Text style={{ width: "50%" }}>
              Mechanic name: {report.mechanicName}
            </Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tablaRows}>
            <Text style={styles.rowCode}>CODE</Text>
            <Text style={styles.rowDescription}>WORK DESCRIPTION</Text>
            <Text style={styles.rowLabor}>LABOR TIME</Text>
            <Text style={styles.rowPart}>PARTS</Text>
            <Text style={styles.rowQuantity}>QUAN.</Text>
            <Text style={styles.rowTotal}>TOTAL</Text>
          </View>
          {report.tableData.map((report) => (
            <View style={styles.tablaRows}>
              <Text style={styles.rowCode}>{report.code}</Text>
              <Text style={styles.rowDescription}>
                {report.workDescription}
              </Text>
              <Text style={styles.rowLabor}>{report.laborTime}</Text>
              <Text style={styles.rowPart}>{report.parts}</Text>
              <Text style={styles.rowQuantity}>{report.quantity}</Text>
              <Text style={styles.rowTotal}>{report.total}</Text>
            </View>
          ))}
        </View>

        <View style={styles.table}>
          <View style={styles.tiresRow}>
            <Text style={styles.tiresTitle}>Tires Tread Deep</Text>
          </View>
          <View style={styles.tiresRow}>
            <View style={styles.tireCheck}>
              <Text style={report.RIF ? styles.checked : styles.check}></Text>
              <Text>RIF</Text>
              <Text style={{ marginLeft: "10px" }}>{report.RIFData || ""}</Text>
              <Text style={{ marginLeft: "10px" }}>
                {report.RIFData2 || ""}
              </Text>
            </View>
            <View style={styles.tireCheck}>
              <Text style={report.ROF ? styles.checked : styles.check}></Text>
              <Text>ROF</Text>
              <Text style={{ marginLeft: "10px" }}>{report.ROFData || ""}</Text>
              <Text style={{ marginLeft: "10px" }}>
                {report.ROFData2 || ""}
              </Text>
            </View>
            <View style={styles.tireCheck}>
              <Text style={report.RIR ? styles.checked : styles.check}></Text>
              <Text>RIR</Text>
              <Text style={{ marginLeft: "10px" }}>{report.RIRData || ""}</Text>
              <Text style={{ marginLeft: "10px" }}>
                {report.RIRData2 || ""}
              </Text>
            </View>
            <View style={styles.tireCheck}>
              <Text style={report.ROR ? styles.checked : styles.check}></Text>
              <Text>ROR</Text>
              <Text style={{ marginLeft: "10px" }}>{report.RORData || ""}</Text>
              <Text style={{ marginLeft: "10px" }}>
                {report.RORData2 || ""}
              </Text>
            </View>
          </View>
          <View style={styles.tiresRow}>
            <View style={styles.tireCheck}>
              <Text style={report.LIF ? styles.checked : styles.check}></Text>
              <Text>LIF</Text>
              <Text style={{ marginLeft: "10px" }}>{report.LIFData || ""}</Text>
              <Text style={{ marginLeft: "10px" }}>
                {report.LIFData2 || ""}
              </Text>
            </View>
            <View style={styles.tireCheck}>
              <Text style={report.LOF ? styles.checked : styles.check}></Text>
              <Text>LOF</Text>
              <Text style={{ marginLeft: "10px" }}>{report.LOFData || ""}</Text>
              <Text style={{ marginLeft: "10px" }}>
                {report.LOFData2 || ""}
              </Text>
            </View>
            <View style={styles.tireCheck}>
              <Text style={report.LIR ? styles.checked : styles.check}></Text>
              <Text>LIR</Text>
              <Text style={{ marginLeft: "10px" }}>{report.LIRData || ""}</Text>
              <Text style={{ marginLeft: "10px" }}>
                {report.LIRData2 || ""}
              </Text>
            </View>
            <View style={styles.tireCheck}>
              <Text style={report.LOR ? styles.checked : styles.check}></Text>
              <Text>LOR</Text>
              <Text style={{ marginLeft: "10px" }}>{report.LORData || ""}</Text>
              <Text style={{ marginLeft: "10px" }}>
                {report.LORData2 || ""}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
