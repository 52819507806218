import { useSelector } from "react-redux";
import { RootState } from "../../../../../interfaces/ReduxState";
import DataSelector from "../../../../../components/DataSelector/DataSelector";

import styles from "./CodeSelector.module.css";

export interface Props {
  selected: string;
  onClose: () => void;
  onSelect: (id: string) => void;
}

export default function CodeSelector({ selected, onClose, onSelect }: Props) {
  const services = useSelector(
    (state: RootState) => state.reports.lists.services
  );

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <header className={styles.header}>
          <h3 className={styles.headerTitle}>Services</h3>
          <button
            className={styles.headerClose}
            type="button"
            onClick={onClose}
          >
            X
          </button>
        </header>
        <form className={styles.form}>
          <DataSelector
            data={services.map((data) => ({
              id: `${data.code} - ${data.description}`,
              label: `${data.code} - ${data.description}`,
            }))}
            placeHolder={"Select a service"}
            selected={[selected]}
            onSelect={onSelect}
            height="500px"
            onRemove={() => onSelect("")}
          />
          <div className={styles.btnContainer}>
            <button
              className="btn btn-outline-success"
              type="button"
              onClick={onClose}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
