import {
  Reports,
  ReportTypes,
} from "../../../../interfaces/ReportsModels/Reports";

import styles from "./ReportRow.module.css";
import editSvg from "../../../../assets/icons/edit.svg";
import removeSvg from "../../../../assets/icons/remove.svg";
import dateFormat from "../../../../scripts/dateFormat";
import reportNumber from "../../../../scripts/reportNumber";
import fileSvg from "../../../../assets/icons/file.svg";

interface Props {
  report: Reports;
  type: ReportTypes;
  handleEdit: (report: Reports) => void;
  handleDelete: (id: number) => void;
  handleOpenPDF: (report: Reports) => void;
}

export default function ReportRow({
  report,
  type,
  handleEdit,
  handleDelete,
  handleOpenPDF,
}: Props) {
  return (
    <tr className={styles.row}>
      <span>
        {Number(type) === ReportTypes.WORK
          ? "Work"
          : Number(type) === ReportTypes.INSPECTION
          ? "Inspection"
          : ""}
      </span>
      <span>{reportNumber(report.numero, type)}</span>
      <span>{dateFormat(report.date)}</span>
      <span>{report.userName}</span>
      <button
        className="btn btn-outline-danger"
        type="button"
        onClick={() => handleOpenPDF(report)}
      >
        <img src={fileSvg} alt="remove" />
      </button>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleEdit(report)}
      >
        <img src={editSvg} alt="edit" />
      </button>
      <button
        className="btn btn-outline-danger"
        type="button"
        onClick={() => handleDelete(report.numero!)}
      >
        <img src={removeSvg} alt="remove" />
      </button>
    </tr>
  );
}
