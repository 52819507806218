import { ReportTypes } from "../../interfaces/ReportsModels/Reports";
import { Inspection } from "../../interfaces/ReportsModels/Inspection";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import reportNumber from "../../scripts/reportNumber";
import dateFormat from "../../scripts/dateFormat";

import check from "../../assets/icons/check.png";

const styles = StyleSheet.create({
  page: {
    padding: "30px",
    fontSize: "10px",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: "calc(100% - 50px)",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "5px",
    fontSize: "10px",
    border: "1px solid grey",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "20px",
    fontSize: "25px",
  },
  data: {
    display: "flex",
    padding: "5px",
    border: "1px solid grey",
  },
  dataRow: {
    display: "flex",
    flexDirection: "row",
  },
  dataElement: {
    flexGrow: 1,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "auto",
    marginTop: 10,
    fontSize: "10px",
    borderLeft: "1px solid grey",
    borderTop: "1px solid grey",
  },
  tablaRows: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderBottom: "1px solid grey",
    borderRight: "1px solid grey",
  },
  itemCol: {
    width: "150px",
    padding: "2px",
    borderRight: "1px solid grey",
  },
  checkCol: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30px",
    padding: "2px",
    borderRight: "1px solid grey",
  },
  checked: {
    width: "30px",
    padding: "2px",
    backgroundColor: "black",
  },
  checkImg: {
    width: "15",
    height: "10px",
  },
  repairCol: {
    display: "flex",
    flexDirection: "column",
    width: "240px",
    padding: "2px",
    borderRight: "1px solid grey",
  },
  laborCol: {
    width: "50px",
    padding: "2px",
    borderRight: "1px solid grey",
  },
  materialCol: {
    width: "60px",
    padding: "2px",
  },
  text: {
    padding: "5px",
    textAlign: "center",
    borderRight: "1px solid grey",
  },
  endData: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "10px",
  },
  textEnd: {
    padding: "5px 10px",
    border: "1px solid gray",
  },
  dataEnd: {
    padding: "5px",
    border: "1px solid gray",
    textAlign: "right",
  },
  declaration: {
    marginTop: "20px",
    marginBottom: "50px",
    textAlign: "justify",
  },
  signatures: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  signatureFlex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  signatureData: {
    marginBottom: "10px",
    width: "150px",
    textAlign: "center",
  },
  signatureDescription: {
    width: "150px",
    paddingTop: "5px",
    textAlign: "center",
    borderTop: "1px solid black",
  },
  cellDivisor: {
    borderBottom: "1px solid #ccc",
  },
  cell: {
    borderBottom: "none",
  },
});

interface Props {
  report: Inspection;
}

// Create Document Component
export default function InspectionPDF({ report }: Props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>
            Visegu Maintenance Service (510)719-1444 percyruiz@visegu.com
          </Text>
        </View>

        <View style={styles.title}>
          <Text>Inspection report</Text>
          <Text style={{ fontSize: "15px" }}>
            #{reportNumber(report.numero, ReportTypes.INSPECTION)}
          </Text>
        </View>

        <View style={styles.data}>
          <View style={styles.dataRow}>
            <Text style={styles.dataElement}>
              Last Annual Periodic Inspection / FMCSA:{" "}
              {report.yearFMCSA ? dateFormat(report.yearFMCSA) : ""}
            </Text>
            <Text style={styles.dataElement}>
              New FMCSA: {report.FMCSA ? dateFormat(report.FMCSA) : ""}
            </Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.dataElement}>
              Last California Periodic Inspection / BIT:{" "}
              {report.yearBIT ? dateFormat(report.yearBIT) : ""}
            </Text>
            <Text style={styles.dataElement}>
              New BIT: {report.BIT ? dateFormat(report.BIT) : ""}
            </Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.dataElement}>
              License Number: {report.licenseNumber}
            </Text>
            <Text style={styles.dataElement}>State: {report.state}</Text>
            <Text style={styles.dataElement}>Location: {report.location}</Text>
          </View>
          <View style={styles.dataRow}>
            <Text style={styles.dataElement}>
              Equipment Mark and Number: {report.equipment}
            </Text>
            <Text style={styles.dataElement}>
              Chassis Owwner or Lessor: {report.ownerOrLessor}
            </Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>INSPECTION ITEM</Text>
            <Text style={styles.checkCol}>OK</Text>
            <Text style={styles.repairCol}>REPAIR / REPLACE ITEMS</Text>
            <Text style={styles.laborCol}>LABOR</Text>
            <Text style={styles.materialCol}>MATERIAL</Text>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>BREAKES, DRUMS, SEALS</Text>
            {report.ok1 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}
            <View style={styles.repairCol}>
              <Text style={report.repair21 ? styles.cellDivisor : styles.cell}>
                {report.repair11}
              </Text>
              <Text style={report.repair31 ? styles.cellDivisor : styles.cell}>
                {report.repair21}
              </Text>
              <Text>{report.repair31}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor1}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material1}</Text>
            </View>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>SLACK ADJUSTMENT</Text>
            {report.ok2 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}
            <View style={styles.repairCol}>
              <Text style={report.repair22 ? styles.cellDivisor : styles.cell}>
                {report.repair12}
              </Text>
              <Text style={report.repair32 ? styles.cellDivisor : styles.cell}>
                {report.repair22}
              </Text>
              <Text>{report.repair32}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor2}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material2}</Text>
            </View>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>
              AIR SYSTEM, AIR VALVES, HOSES, BRAKE CHAMBER
            </Text>
            {report.ok3 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}
            <View style={styles.repairCol}>
              <Text style={report.repair23 ? styles.cellDivisor : styles.cell}>
                {report.repair13}
              </Text>
              <Text style={report.repair33 ? styles.cellDivisor : styles.cell}>
                {report.repair23}
              </Text>
              <Text>{report.repair33}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor3}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material3}</Text>
            </View>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>LIGHT AND REPLECTORS</Text>
            {report.ok4 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}{" "}
            <View style={styles.repairCol}>
              <Text style={report.repair24 ? styles.cellDivisor : styles.cell}>
                {report.repair14}
              </Text>
              <Text style={report.repair34 ? styles.cellDivisor : styles.cell}>
                {report.repair24}
              </Text>
              <Text>{report.repair34}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor4}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material4}</Text>
            </View>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>7 WAY PLUG AND WITING</Text>
            {report.ok5 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}
            <View style={styles.repairCol}>
              <Text style={report.repair25 ? styles.cellDivisor : styles.cell}>
                {report.repair15}
              </Text>
              <Text style={report.repair35 ? styles.cellDivisor : styles.cell}>
                {report.repair25}
              </Text>
              <Text>{report.repair35}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor5}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material5}</Text>
            </View>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>TIRES, WHEELS AND RIMS</Text>
            {report.ok6 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}
            <View style={styles.repairCol}>
              <Text style={report.repair26 ? styles.cellDivisor : styles.cell}>
                {report.repair16}
              </Text>
              <Text style={report.repair36 ? styles.cellDivisor : styles.cell}>
                {report.repair26}
              </Text>
              <Text>{report.repair36}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor6}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material6}</Text>
            </View>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>
              LANDING GEAR, LEG, BRANCES, CRANK, HANDLE
            </Text>
            {report.ok7 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}
            <View style={styles.repairCol}>
              <Text style={report.repair27 ? styles.cellDivisor : styles.cell}>
                {report.repair17}
              </Text>
              <Text style={report.repair37 ? styles.cellDivisor : styles.cell}>
                {report.repair27}
              </Text>
              <Text>{report.repair37}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor7}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material7}</Text>
            </View>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>TWISTLOCKS, PIN LOCKS, LATCHES</Text>
            {report.ok8 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}
            <View style={styles.repairCol}>
              <Text style={report.repair28 ? styles.cellDivisor : styles.cell}>
                {report.repair18}
              </Text>
              <Text style={report.repair38 ? styles.cellDivisor : styles.cell}>
                {report.repair28}
              </Text>
              <Text>{report.repair38}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor8}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material8}</Text>
            </View>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>
              SUSPENSION U-BOLTS, HANGERS, RADIUS ROD, AXLES
            </Text>
            {report.ok9 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}
            <View style={styles.repairCol}>
              <Text style={report.repair29 ? styles.cellDivisor : styles.cell}>
                {report.repair19}
              </Text>
              <Text style={report.repair39 ? styles.cellDivisor : styles.cell}>
                {report.repair29}
              </Text>
              <Text>{report.repair39}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor9}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material9}</Text>
            </View>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>
              BODY FRAMES, STRUCTURAL, KING PIN/PLATE, CROSS MEMBERS
            </Text>
            {report.ok10 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}
            <View style={styles.repairCol}>
              <Text style={report.repair210 ? styles.cellDivisor : styles.cell}>
                {report.repair110}
              </Text>
              <Text style={report.repair310 ? styles.cellDivisor : styles.cell}>
                {report.repair210}
              </Text>
              <Text>{report.repair310}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor10}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material10}</Text>
            </View>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>DECALS / LOGO</Text>
            {report.ok11 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}
            <View style={styles.repairCol}>
              <Text style={report.repair211 ? styles.cellDivisor : styles.cell}>
                {report.repair111}
              </Text>
              <Text style={report.repair311 ? styles.cellDivisor : styles.cell}>
                {report.repair211}
              </Text>
              <Text>{report.repair311}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor11}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material11}</Text>
            </View>
          </View>
          <View style={styles.tablaRows}>
            <Text style={styles.itemCol}>
              LUBRICATION, FITTING, LOOCKS, LANDING GEAR, SLIDER
            </Text>
            {report.ok12 ? (
              <View style={styles.checkCol}>
                <Image style={styles.checkImg} source={check} />
              </View>
            ) : (
              <Text style={styles.checkCol}></Text>
            )}
            <View style={styles.repairCol}>
              <Text style={report.repair212 ? styles.cellDivisor : styles.cell}>
                {report.repair112}
              </Text>
              <Text style={report.repair312 ? styles.cellDivisor : styles.cell}>
                {report.repair212}
              </Text>
              <Text>{report.repair312}</Text>
            </View>
            <View style={styles.laborCol}>
              <Text>{report.labor12}</Text>
            </View>
            <View style={styles.materialCol}>
              <Text>{report.material12}</Text>
            </View>
          </View>
        </View>

        <View style={styles.declaration}>
          <Text>
            I CERTIFY THIS UNIT HAS UNDERGONE INSPECTION AND REPAIRS AND THAT IT
            MEETS REQUIREMENTS OF 49 C F 397.17 ANDAB2706 - CALIFORNIA
            COMMERCIAL SAFETY VEHICLE ACT. 1988 BIT ORFHCSA INSPECTION. THIS
            CERTIFICATION DOES NOT APPLY TO DAMAGE OR DEFECTS TO THE UNIT
            RESULTING FROM THE USE OR IMPROPER HANDLING AFTER THE DATE OF OUR
            INSPECTION. WE ARENT RELIABLE FOR ANY DEATH OR INJURIES TO PERSONALE
            OR PROPERTY
          </Text>
        </View>

        <View style={styles.signatures}>
          <View style={styles.signatureFlex}>
            <Text style={styles.signatureData}>{report.userName}</Text>
            <Text style={styles.signatureDescription}>
              PRINT INSPECTION NAME
            </Text>
          </View>
          <View style={styles.signatureFlex}>
            <Text style={styles.signatureDescription}>INSPECTOR SIGNATURE</Text>
          </View>
          <View style={styles.signatureFlex}>
            <Text style={styles.signatureData}>{dateFormat(new Date())}</Text>
            <Text style={styles.signatureDescription}>DATE OF INSPECTION</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
