import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../interfaces/ReduxState";
import { Customers } from "../../../../interfaces/Customers";
import {
  TableData,
  WorkReport,
  WorkReportError,
  initTableData,
  initWorkReport,
  initWorkReportError,
} from "../../../../interfaces/ReportsModels/Work";
import formatDateYYYYMMDD from "../../../../scripts/formatDateYYYYMMDD";

import Checkbox from "../../../../components/Inputs/Checkbox";
import CodeSelector from "./CodeSelector/CodeSelector";
import ProductSelector from "./ProductSelector/ProductSelector";

import styles from "./WorkForm.module.css";

export interface Props {
  data: any;
  handleClose: () => void;
  handleSubmit: (workReport: WorkReport) => void;
}

export default function WorkForm({ data, handleClose, handleSubmit }: Props) {
  const list = useSelector((state: RootState) => state.reports.lists);
  const user = useSelector((state: RootState) => state.login);
  const customers = useSelector((state: RootState) => state.customers);
  const products = useSelector((state: RootState) => state.products.data);
  const [report, setReport] = useState<WorkReport>(initWorkReport());
  const [error, setError] = useState<WorkReportError>(initWorkReportError());
  const [codeForm, setCodeForm] = useState<{ show: boolean; index: number }>({
    show: false,
    index: 0,
  });
  const [productForm, setProductForm] = useState<{
    show: boolean;
    index: number;
  }>({
    show: false,
    index: 0,
  });

  // If data is selected for editing, update product local data
  useEffect(() => {
    if (data) {
      setReport({
        ...data,
      });
    } else {
      setReport({
        ...report,
        userId: user.id,
        userName: user.name,
        mechanicName: user.name,
      });
    }
  }, [data, user]);

  // Change report
  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    // Get values
    const { name, value, type } = event.target;

    // Update report data
    if (type === "date") {
      let dateValue = new Date(`${value} 00:00:00`);
      if (!isNaN(dateValue?.getTime()))
        setReport({ ...report, [name]: dateValue });
    } else setReport({ ...report, [event.target.name]: event.target.value });

    // Delete error
    setError({ ...error, [event.target.name]: "" });
  }

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    let data: any = {
      ...report,
      [event.target.name]: event.target.checked,
    };

    if (!event.target.checked) {
      data[`${[event.target.name]}Data`] = "";
      data[`${[event.target.name]}Data2`] = "";
    }

    setReport(data);
  }

  // Change table item
  function handleChangeRow(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) {
    const { name, value } = event.target;

    if (name === "code") {
      setReport({
        ...report,
        tableData: report.tableData.map((data, i) =>
          i === index
            ? {
                ...data,
                [name]: value,
                workDescription:
                  products.find((p) => p.skuNumber === value)?.description ||
                  "",
              }
            : data
        ),
      });
    } else {
      setReport({
        ...report,
        tableData: report.tableData.map((data, i) =>
          i === index ? { ...data, [name]: value } : data
        ),
      });
    }
  }

  // Delete table row
  function handleDeleteRow(index: number) {
    setReport({
      ...report,
      tableData: report.tableData.filter((data, i) => i !== index),
    });
  }

  // Add new row in the table
  function handleAddRow() {
    setReport({
      ...report,
      tableData: [...report.tableData, initTableData()],
    });
  }

  // Sbubmit data
  function handleLocalSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (validations()) {
      // Set date and hour to crete the report
      handleSubmit({ ...report, date: new Date() });
      handleClose();
    }
  }

  // Errors validations
  function validations() {
    let errors: WorkReportError = initWorkReportError();
    let value = true;

    setError(errors);
    return value;
  }

  const handleCodeSelect = (index?: number, id?: string) => {
    if (index !== undefined && index >= 0 && !id) {
      setCodeForm({
        show: true,
        index: index,
      });
    } else if (index !== undefined && index >= 0 && id) {
      setCodeForm({
        show: false,
        index: 0,
      });
      setReport({
        ...report,
        tableData: report.tableData.map(
          (data: TableData, i: number): TableData =>
            i === index
              ? {
                  ...data,
                  code: id,
                  workDescription:
                    list.services.find((service) => service.code === id)
                      ?.description || "",
                }
              : data
        ),
      });
    } else {
      setCodeForm({
        show: false,
        index: 0,
      });
    }
  };

  const handlePartSelect = (index?: number, product?: string) => {
    if (index !== undefined && index >= 0 && !product) {
      setProductForm({
        show: true,
        index: index,
      });
    } else if (index !== undefined && index >= 0 && product) {
      setProductForm({
        show: false,
        index: 0,
      });
      setReport({
        ...report,
        tableData: report.tableData.map(
          (data: TableData, i: number): TableData =>
            i === index
              ? {
                  ...data,
                  parts: product,
                }
              : data
        ),
      });
    } else {
      setProductForm({
        show: false,
        index: 0,
      });
    }
  };

  return (
    <div className={styles.background}>
      {codeForm.show && (
        <CodeSelector
          selected={report.tableData[codeForm.index].code}
          onSelect={(id: string) => handleCodeSelect(codeForm.index, id)}
          onClose={handleCodeSelect}
        />
      )}
      {productForm.show && (
        <ProductSelector
          selected={report.tableData[productForm.index].parts}
          onSelect={(product: string) =>
            handlePartSelect(productForm.index, product)
          }
          onClose={handlePartSelect}
        />
      )}
      <div className={styles.container}>
        <header className={styles.header}>
          <h3 className={styles.headerTitle}>New Report</h3>
          <button
            className={styles.headerClose}
            type="button"
            onClick={handleClose}
          >
            X
          </button>
        </header>
        <form className={styles.form} onSubmit={handleLocalSubmit}>
          <h2 className={styles.headerTitle}>Work order</h2>
          <div className={styles.formHeader}>
            <div>
              <label htmlFor="customer">Customer</label>
              <select
                id="customer"
                name="customer"
                value={report.customer}
                onChange={handleChange}
              >
                <option>Seleccionar</option>
                {customers?.map((customer: Customers) => (
                  <option value={customer.name}>{customer.name}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="location">Location</label>
              <select
                id="location"
                name="location"
                value={report.location}
                onChange={handleChange}
              >
                <option>Seleccionar</option>
                {list.locations.map((location) => (
                  <option value={location}>{location}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="timeToStartServices">
                Time to start services
              </label>
              <input
                id="timeToStartServices"
                name="timeToStartServices"
                type="string"
                value={report.timeToStartServices}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.formHeader}>
            <div>
              <label htmlFor="equipment">Equipment</label>
              <input
                id="equipment"
                name="equipment"
                type="text"
                value={report.equipment}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="dateOfRepair">Date of repair</label>
              <input
                id="dateOfRepair"
                name="dateOfRepair"
                type="date"
                value={
                  report.dateOfRepair
                    ? formatDateYYYYMMDD(report.dateOfRepair)
                    : ""
                }
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="timeFinishService">Time finish service</label>
              <input
                id="timeFinishService"
                name="timeFinishService"
                type="text"
                value={report.timeFinishService}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.formHeader}>
            <div>
              <label htmlFor="licensePlate">License plate</label>
              <input
                id="licensePlate"
                name="licensePlate"
                value={report.licensePlate}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="PO">PO</label>
              <input
                id="PO"
                name="PO"
                value={report.PO}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.formHeader}>
            <div>
              <label htmlFor="VIN">VIN</label>
              <input
                id="VIN"
                name="VIN"
                value={report.VIN}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="mechanicName">Mechanic name</label>
              <input
                id="mechanicName"
                name="mechanicName"
                value={report.mechanicName}
                onChange={handleChange}
                disabled={true}
              />
            </div>
          </div>
          <table className={styles.table}>
            <thead>
              <tr className={styles.row}>
                <th>CODE</th>
                <th>WORK DESCRIPTION</th>
                <th>LABOR TIME</th>
                <th>PARTS</th>
                <th>QUANTITY</th>
                <th>TOTAL</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {report?.tableData.map((data, i) => (
                <RowInput
                  key={i}
                  data={data}
                  code={products.map((product) => product.skuNumber)}
                  onCodeSelect={() => handleCodeSelect(i)}
                  onPartSelect={() => handlePartSelect(i)}
                  onDelete={() => handleDeleteRow(i)}
                  handleChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLSelectElement
                    >
                  ) => handleChangeRow(event, i)}
                />
              ))}
              <tr>
                <td colSpan={6}>
                  <button
                    className={styles.btnAddRow}
                    type="button"
                    onClick={handleAddRow}
                  >
                    +
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>Tires Tread Depp</td>
              </tr>
              <tr>
                <td className={styles.tiresFlex}>
                  <div>
                    <Checkbox
                      name="RIF"
                      label="RIF"
                      value={report.RIF}
                      handleCheck={handleCheck}
                    />
                    <input
                      name="RIFData"
                      type="text"
                      value={report.RIFData}
                      onChange={handleChange}
                      disabled={!report.RIF}
                    />
                    <input
                      name="RIFData2"
                      type="text"
                      value={report.RIFData2}
                      onChange={handleChange}
                      disabled={!report.RIF}
                    />
                  </div>
                  <div>
                    <Checkbox
                      name="ROF"
                      label="ROF"
                      value={report.ROF}
                      handleCheck={handleCheck}
                    />
                    <input
                      name="ROFData"
                      type="text"
                      value={report.ROFData}
                      onChange={handleChange}
                      disabled={!report.ROF}
                    />
                    <input
                      name="ROFData2"
                      type="text"
                      value={report.ROFData2}
                      onChange={handleChange}
                      disabled={!report.ROF}
                    />
                  </div>
                  <div>
                    <Checkbox
                      name="RIR"
                      label="RIR"
                      value={report.RIR}
                      handleCheck={handleCheck}
                    />
                    <input
                      name="RIRData"
                      type="text"
                      value={report.RIRData}
                      onChange={handleChange}
                      disabled={!report.RIR}
                    />
                    <input
                      name="RIRData2"
                      type="text"
                      value={report.RIRData2}
                      onChange={handleChange}
                      disabled={!report.RIR}
                    />
                  </div>
                  <div>
                    <Checkbox
                      name="ROR"
                      label="ROR"
                      value={report.ROR}
                      handleCheck={handleCheck}
                    />
                    <input
                      name="RORData"
                      type="text"
                      value={report.RORData}
                      onChange={handleChange}
                      disabled={!report.ROR}
                    />
                    <input
                      name="RORData2"
                      type="text"
                      value={report.RORData2}
                      onChange={handleChange}
                      disabled={!report.ROR}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className={styles.tiresFlex}>
                  <div>
                    <Checkbox
                      name="LIF"
                      label="LIF"
                      value={report.LIF}
                      handleCheck={handleCheck}
                    />
                    <input
                      name="LIFData"
                      type="text"
                      value={report.LIFData}
                      onChange={handleChange}
                      disabled={!report.LIF}
                    />
                    <input
                      name="LIFData2"
                      type="text"
                      value={report.LIFData2}
                      onChange={handleChange}
                      disabled={!report.LIF}
                    />
                  </div>
                  <div>
                    <Checkbox
                      name="LOF"
                      label="LOF"
                      value={report.LOF}
                      handleCheck={handleCheck}
                    />
                    <input
                      name="LOFData"
                      type="text"
                      value={report.LOFData}
                      onChange={handleChange}
                      disabled={!report.LOF}
                    />
                    <input
                      name="LOFData2"
                      type="text"
                      value={report.LOFData2}
                      onChange={handleChange}
                      disabled={!report.LOF}
                    />
                  </div>
                  <div>
                    <Checkbox
                      name="LIR"
                      label="LIR"
                      value={report.LIR}
                      handleCheck={handleCheck}
                    />
                    <input
                      name="LIRData"
                      type="text"
                      value={report.LIRData}
                      onChange={handleChange}
                      disabled={!report.LIR}
                    />
                    <input
                      name="LIRData2"
                      type="text"
                      value={report.LIRData2}
                      onChange={handleChange}
                      disabled={!report.LIR}
                    />
                  </div>
                  <div>
                    <Checkbox
                      name="LOR"
                      label="LOR"
                      value={report.LOR}
                      handleCheck={handleCheck}
                    />
                    <input
                      name="LORData"
                      type="text"
                      value={report.LORData}
                      onChange={handleChange}
                      disabled={!report.LOR}
                    />
                    <input
                      name="LORData2"
                      type="text"
                      value={report.LORData2}
                      onChange={handleChange}
                      disabled={!report.LOR}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <button className="btn btn-success" type="submit">
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

interface RowProps {
  data: TableData;
  code: string[];
  onCodeSelect: () => void;
  onPartSelect: () => void;
  onDelete: () => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

function RowInput({
  data,
  code,
  onCodeSelect,
  onPartSelect,
  onDelete,
  handleChange,
}: RowProps) {
  const handleGetLabors = (): string[] => {
    const labors: string[] = [];

    for (let index = 0; index < 24; index += +0.05) {
      labors.push(index.toFixed(2));
    }

    return labors;
  };

  return (
    <tr className={styles.row}>
      <td>
        {data.code ? (
          <span>{data.code}</span>
        ) : (
          <button type="button" onClick={onCodeSelect}>
            Select
          </button>
        )}
      </td>
      <td>
        <span>{data.workDescription || "-"}</span>
      </td>
      <td>
        <select name="laborTime" value={data.laborTime} onChange={handleChange}>
          <option>Select</option>
          {handleGetLabors().map((labor) => (
            <option value={labor}>{labor}</option>
          ))}
        </select>
      </td>
      <td>
        {data.parts ? (
          <span>{data.parts}</span>
        ) : (
          <button type="button" onClick={onPartSelect}>
            Select
          </button>
        )}
      </td>
      <td>
        <input name="quantity" value={data.quantity} onChange={handleChange} />
      </td>
      <td>
        <input name="total" value={data.total} onChange={handleChange} />
      </td>
      <td>
        <button className="btn btn-danger" onClick={onDelete} type="button">
          x
        </button>
      </td>
    </tr>
  );
}
