import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/ReduxState";
import { Customers } from "../../interfaces/Customers";
import {
  getAllCustomers,
  postCustomers,
  updateCustomer as updateCustomerAction,
  deleteCustomer as deleteCustomerAction,
} from "../../redux/actions/customers";
import swal from "sweetalert";
import { isYieldExpression } from "typescript";

interface UseCustomers {
  data: Customers[];
  set: (customer: Customers) => Promise<void>;
  get: () => Promise<void>;
  update: (customer: Customers) => Promise<void>;
  delete: (customerId: string) => Promise<void>;
}

function useCustomer(): UseCustomers {
  const dispatch = useDispatch();
  const customers = useSelector((state: RootState) => state.customers);

  async function setCustomer(customer: Customers): Promise<void> {
    dispatch(openLoading());
    return dispatch<any>(postCustomers(customer))
      .then(() => {
        dispatch<any>(closeLoading());
        swal("Created", "Customer created successfully", "success");
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch<any>(closeLoading());
        swal("Error", "Error to create the customer, try later", "error");
      });
  }

  async function getCustomers(): Promise<void> {
    dispatch(openLoading());
    return dispatch<any>(getAllCustomers())
      .then(() => {
        dispatch<any>(closeLoading());
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch<any>(closeLoading());
        swal("Error", "Error to get the customers, try later", "error");
      });
  }

  async function updateCustomer(customer: Customers): Promise<void> {
    dispatch(openLoading());
    return dispatch<any>(updateCustomerAction(customer))
      .then(() => {
        dispatch<any>(closeLoading());
        swal("Updated", "Customer updated successfully", "success");
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch<any>(closeLoading());
        swal("Error", "Error to update the customer, try later", "error");
      });
  }

  async function deleteCustomer(customerId: string): Promise<void> {
    swal({
      text: "Are you sure you want to delete this customer?",
      icon: "warning",
      buttons: {
        Yes: true,
        No: true,
      },
    }).then((response: string) => {
      if (response === "Yes") {
        dispatch(openLoading());
        return dispatch<any>(deleteCustomerAction(customerId))
          .then(() => {
            dispatch<any>(closeLoading());
            swal("Deleted", "Customer deleted successfully", "success");
          })
          .catch((error: Error) => {
            console.log(error);
            dispatch<any>(closeLoading());
            swal("Error", "Error to delete the customer, try later", "error");
          });
      }
    });
  }

  return {
    data: customers,
    set: setCustomer,
    get: getCustomers,
    update: updateCustomer,
    delete: deleteCustomer,
  };
}

export default useCustomer;
