import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../interfaces/ReduxState";
import {
  initReportsFilters,
  ReportsFilters,
  ReportTypes,
} from "../../../../interfaces/ReportsModels/Reports";

import SelectInput from "../../../../components/Inputs/SelectInput";

import style from "./Filters.module.css";
import filterSvg from "../../../../assets/icons/filter.svg";
import Input from "../../../../components/Inputs/Input";
import formatDateYYYYMMDD from "../../../../scripts/formatDateYYYYMMDD";

interface Props {
  filters: ReportsFilters;
  handleSubmit: (filters: ReportsFilters) => void;
}

export default function Filters({ filters, handleSubmit }: Props) {
  const users = useSelector((state: RootState) => state.users);
  const [open, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<ReportsFilters>(initReportsFilters());
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Set filters
  useEffect(() => {
    setFilter(filters);
  }, [filters]);

  // Close filters
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Filter change
  function handleChangeFilter(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    const { name, value, type } = event.target;

    if (type === "date") {
      const date = new Date(`${value} 00:00:00`);
      if (!isNaN(date.getTime())) setFilter({ ...filter, date });
    } else {
      setFilter({ ...filter, [name]: value });
    }
  }

  // Toggle dropdown filter
  function handleFilter() {
    setOpen(!open);
  }

  // Submit filters data
  function handleLocalSubmit(event: React.FormEvent) {
    event.preventDefault();
    handleSubmit(filter);
    handleFilter();
  }

  // Clear all filters
  function handleClear() {
    handleSubmit(initReportsFilters());
  }

  return (
    <div className={style.filter} ref={dropdownRef}>
      <button
        className={`btn btn-outline-primary ${style.btnFilter}`}
        type="button"
        onClick={handleFilter}
      >
        <span>Filtros</span>
        <img src={filterSvg} alt="filtros" />
      </button>
      {open ? (
        <form className={style.filterContainer} onSubmit={handleLocalSubmit}>
          <SelectInput
            name="type"
            label="Type"
            list={[
              { id: ReportTypes.WORK.toString(), label: "Work" },
              { id: ReportTypes.INSPECTION.toString(), label: "Inspection" },
            ]}
            value={filter.type}
            handleChange={handleChangeFilter}
          />
          <Input
            name="date"
            label="Fecha"
            value={formatDateYYYYMMDD(filter.date)}
            type="date"
            handleChange={handleChangeFilter}
          />
          <SelectInput
            name="user"
            label="User"
            list={users.map((user) => ({ id: user.id!, label: user.name }))}
            value={filter.user}
            handleChange={handleChangeFilter}
          />
          <button className="btn btn-success" type="submit">
            Aplicar
          </button>
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={handleClear}
          >
            Borrar
          </button>
        </form>
      ) : null}
    </div>
  );
}
