export interface WorkReport {
  id?: string;
  numero: number;
  date: Date;
  userId?: string;
  userName: string;

  customer: string;
  location: string;
  timeToStartServices: string;
  equipment: string;
  dateOfRepair: Date | null;
  timeFinishService: string;
  licensePlate: string;
  PO: string;
  VIN: string;
  mechanicName: string;
  RIF: boolean;
  RIFData: string;
  RIFData2: string;
  ROF: boolean;
  ROFData: string;
  ROFData2: string;
  RIR: boolean;
  RIRData: string;
  RIRData2: string;
  ROR: boolean;
  RORData: string;
  RORData2: string;
  LIF: boolean;
  LIFData: string;
  LIFData2: string;
  LOF: boolean;
  LOFData: string;
  LOFData2: string;
  LIR: boolean;
  LIRData: string;
  LIRData2: string;
  LOR: boolean;
  LORData: string;
  LORData2: string;
  tableData: Array<TableData>;
}

export interface TableData {
  code: string;
  workDescription: string;
  laborTime: number;
  parts: string;
  quantity: string;
  total: string;
}

export interface WorkReportError {
  customer: string;
  location: string;
  timeToStartServices: string;
  equipment: string;
  dateOfRepair: string;
  timeFinishService: string;
  licensePlate: string;
  PO: string;
  VIN: string;
  mechanicName: string;
  table: string;
}

export const initWorkReport = (): WorkReport => ({
  numero: 0,
  date: new Date(),
  userName: "",

  customer: "",
  location: "",
  timeToStartServices: "",
  equipment: "",
  dateOfRepair: new Date(),
  timeFinishService: "",
  licensePlate: "",
  PO: "",
  VIN: "",
  mechanicName: "",
  RIF: false,
  RIFData: "",
  RIFData2: "",
  ROF: false,
  ROFData: "",
  ROFData2: "",
  RIR: false,
  RIRData: "",
  RIRData2: "",
  ROR: false,
  RORData: "",
  RORData2: "",
  LIF: false,
  LIFData: "",
  LIFData2: "",
  LOF: false,
  LOFData: "",
  LOFData2: "",
  LIR: false,
  LIRData: "",
  LIRData2: "",
  LOR: false,
  LORData: "",
  LORData2: "",
  tableData: [],
});

export const initWorkReportError = (): WorkReportError => ({
  customer: "",
  location: "",
  timeToStartServices: "",
  equipment: "",
  dateOfRepair: "",
  timeFinishService: "",
  licensePlate: "",
  PO: "",
  VIN: "",
  mechanicName: "",
  table: "",
});

export const initTableData = (): TableData => ({
  code: "",
  workDescription: "",
  laborTime: 0,
  parts: "",
  quantity: "",
  total: "",
});
