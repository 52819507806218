import { Customers } from "../../../../interfaces/Customers";

import style from "./CustomersRow.module.css";

import editSvg from "../../../../assets/icons/edit.svg";
import removeSvg from "../../../../assets/icons/remove.svg";

interface Props {
  customer: Customers;
  handleEdit: (user: Customers) => void;
  handleDelete: (user: string) => void;
}

export default function CustomersRow({
  customer,
  handleEdit,
  handleDelete,
}: Props) {
  return (
    <tr className={style.row}>
      <td>{customer.name}</td>
      <td>{customer.fullName}</td>
      <td>{customer.phoneNumber}</td>
      <td>{customer.email}</td>
      <td>{customer.billingAdress}</td>
      <td>{customer.shippingAdress}</td>
      <td>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={() => handleEdit(customer)}
        >
          <img src={editSvg} alt="edit" />
        </button>
      </td>
      <td>
        <button
          className="btn btn-outline-danger"
          type="button"
          onClick={() => handleDelete(customer.id!)}
        >
          <img src={removeSvg} alt="remove" />
        </button>
      </td>
    </tr>
  );
}
