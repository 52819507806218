export interface Customers {
  id?: string;
  name: string;
  phoneNumber: string;
  email: string;
  fullName: string;
  billingAdress: string;
  shippingAdress: string;
}

export interface CustomersError {
  name: string;
  phoneNumber: string;
  email: string;
  fullName: string;
  billingAdress: string;
  shippingAdress: string;
}

export const initCustomers = (): Customers => ({
  name: "",
  phoneNumber: "",
  email: "",
  fullName: "",
  billingAdress: "",
  shippingAdress: "",
});

export const initCustomersError = (): CustomersError => ({
  name: "",
  phoneNumber: "",
  email: "",
  fullName: "",
  billingAdress: "",
  shippingAdress: "",
});
