import axios from "axios";

export default function axiosSetConfig() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.interceptors.response.use(undefined, async (error) => {
    console.error("Interceptor request:", error);
    return Promise.reject(error);
  });

  axios.interceptors.request.use(undefined, async (request) => {
    console.error("Interceptor response:", request);
    return Promise.reject(request);
  });
}
