import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../interfaces/ReduxState";
import {
  Inspection,
  InspectionError,
  getBITDate,
  getFMCSADate,
  initInspection,
  initInspectionError,
} from "../../../../interfaces/ReportsModels/Inspection";
import formatDateYYYYMMDD from "../../../../scripts/formatDateYYYYMMDD";

import CodeSelector from "./CodeSelector/CodeSelector";

import styles from "./InspectionForm.module.css";

export interface Props {
  data: any;
  handleClose: () => void;
  handleSubmit: (inspection: Inspection) => void;
}

export default function InspectionForm({
  data,
  handleClose,
  handleSubmit,
}: Props) {
  const user = useSelector((state: RootState) => state.login);
  const lists = useSelector((state: RootState) => state.reports.lists);
  const [report, setReport] = useState<Inspection>(initInspection(user.name));
  const [error, setError] = useState<InspectionError>(initInspectionError());

  // Part select
  const [selected, setSelected] = useState<{ part: string; value: string }>({
    part: "",
    value: "",
  });

  // If data is selected for editing, update product local data
  useEffect(() => {
    if (data) {
      setReport({
        ...data,
      });
    } else {
      setReport({
        ...report,
        userId: user.id,
        userName: user.name,
      });
    }
  }, [data, user]);

  // Change report
  function handleChange(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) {
    // Get values
    const { name, value, type } = event.target;

    // Update report data
    if (type === "date") {
      let dateValue = new Date(`${value} 00:00:00`);
      if (!isNaN(dateValue?.getTime())) {
        if (name === "yearFMCSA" || name === "FMCSA") {
          setReport({
            ...report,
            [name]: dateValue,
            FMCSA: getFMCSADate(dateValue),
          });
        } else if (name === "yearBIT" || name === "BIT") {
          setReport({
            ...report,
            [name]: dateValue,
            BIT: getBITDate(dateValue),
          });
        }
      } else if (value === "") {
        if (name === "yearFMCSA" || name === "FMCSA") {
          setReport({
            ...report,
            [name]: null,
            FMCSA: null,
          });
        } else if (name === "yearBIT" || name === "BIT") {
          setReport({
            ...report,
            [name]: null,
            BIT: null,
          });
        }
      }
    } else setReport({ ...report, [event.target.name]: event.target.value });

    // Delete error
    setError({ ...error, [event.target.name]: "" });
  }

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
    setReport({ ...report, [event.target.name]: event.target.checked });
  }

  // Sbubmit data
  function handleLocalSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (validations()) {
      // Set date and hour to crete the report
      handleSubmit({ ...report, date: new Date() });
      console.log(report);
      handleClose();
    }
  }

  // Errors validations
  function validations() {
    let errors: InspectionError = initInspectionError();
    let value = true;

    setError(errors);
    return value;
  }

  // Save input name to edit the part
  function handlePartEdit(value?: string, part?: string) {
    if (value) {
      setSelected({ part: part || "", value }); // Save input and value, open part selector
    }
  }

  // Close selector
  function handleClosePartSelctor(value?: string, part?: string) {
    setSelected({ part: "", value: "" }); // Clear selection, close part selector
  }

  // Select new part
  function handlePartSelect(newPart: string) {
    setReport({ ...report, [selected.value as keyof typeof report]: newPart });
    handleClosePartSelctor();
  }

  return (
    <div>
      {selected.value && (
        <CodeSelector
          selected={selected.part}
          onSelect={handlePartSelect}
          onClose={handleClosePartSelctor}
        />
      )}

      <div className={styles.background}>
        <div className={styles.container}>
          <header className={styles.header}>
            <h3 className={styles.headerTitle}>InspectionForm</h3>
            <button
              className={styles.headerClose}
              type="button"
              onClick={handleClose}
            >
              X
            </button>
          </header>
          <form className={styles.form} onSubmit={handleLocalSubmit}>
            <div className={styles.textToComplete}>
              <div className={styles.row}>
                <label>
                  Last Annual Periodic Inspection / FMCSA:
                  <input
                    name="yearFMCSA"
                    type="date"
                    value={formatDateYYYYMMDD(report.yearFMCSA)}
                    onChange={handleChange}
                  />
                </label>

                <label>
                  New FMCSA:
                  <input
                    name="FMCSA"
                    type="date"
                    value={formatDateYYYYMMDD(report.FMCSA)}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className={styles.row}>
                <label>
                  Last California Periodic Inspection / BIT:
                  <input
                    name="yearBIT"
                    type="date"
                    value={formatDateYYYYMMDD(report.yearBIT)}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  New BIT:
                  <input
                    name="BIT"
                    type="date"
                    value={formatDateYYYYMMDD(report.BIT)}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className={styles.row}>
                <label>
                  License Number:
                  <input
                    name="licenseNumber"
                    value={report.licenseNumber}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  State:
                  <select
                    name="state"
                    value={report.state}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    {lists.state.map((data) => (
                      <option value={data}>{data}</option>
                    ))}
                  </select>
                </label>
                <label>
                  Location:
                  <select
                    name="location"
                    value={report.location}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    {lists.locations.map((data) => (
                      <option value={data}>{data}</option>
                    ))}
                  </select>
                </label>
              </div>
              <div className={styles.row}>
                <label>
                  Equipment Mark and Number:
                  <input
                    name="equipment"
                    type="text"
                    value={report.equipment}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Chassis Owwner or Lessor:
                  <input
                    name="ownerOrLessor"
                    type="text"
                    value={report.ownerOrLessor}
                    onChange={handleChange}
                  />
                </label>
              </div>
            </div>
            <table>
              <thead>
                <tr className={styles.rows}>
                  <td>INSPECTION ITEM</td>
                  <td>OK</td>
                  <td>REPAIR / REPLACE ITEMS</td>
                  <td>LABOR CODE</td>
                  <td>MATERIAL CODE</td>
                </tr>
              </thead>
              <tbody className={styles.tableBody}>
                <tr>
                  <td>BREAKES, DRUMS, SEALS</td>
                  <td>
                    <input
                      name="ok1"
                      type="checkbox"
                      checked={report.ok1}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair11}
                      onClick={() =>
                        handlePartEdit("repair11", report.repair11)
                      }
                    >
                      {report.repair11 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair21}
                      onClick={() =>
                        handlePartEdit("repair21", report.repair21)
                      }
                    >
                      {report.repair21 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair31}
                      onClick={() =>
                        handlePartEdit("repair31", report.repair31)
                      }
                    >
                      {report.repair31 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor1"
                      value={report.labor1}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material1"
                      value={report.material1}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>SLACK ADJUSTMENT</td>
                  <td>
                    <input
                      name="ok2"
                      type="checkbox"
                      checked={report.ok2}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair12}
                      onClick={() =>
                        handlePartEdit("repair12", report.repair12)
                      }
                    >
                      {report.repair12 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair22}
                      onClick={() =>
                        handlePartEdit("repair22", report.repair22)
                      }
                    >
                      {report.repair22 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair32}
                      onClick={() =>
                        handlePartEdit("repair32", report.repair32)
                      }
                    >
                      {report.repair32 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor2"
                      value={report.labor2}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material2"
                      value={report.material2}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>AIR SYSTEM, AIR VALVES, HOSES, BRAKE CHAMBER</td>
                  <td>
                    <input
                      name="ok3"
                      type="checkbox"
                      checked={report.ok3}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair13}
                      onClick={() =>
                        handlePartEdit("repair13", report.repair13)
                      }
                    >
                      {report.repair13 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair23}
                      onClick={() =>
                        handlePartEdit("repair23", report.repair23)
                      }
                    >
                      {report.repair23 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair33}
                      onClick={() =>
                        handlePartEdit("repair33", report.repair33)
                      }
                    >
                      {report.repair33 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor3"
                      value={report.labor3}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material3"
                      value={report.material3}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>LIGHT AND REPLECTORS</td>
                  <td>
                    <input
                      name="ok4"
                      type="checkbox"
                      checked={report.ok4}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair14}
                      onClick={() =>
                        handlePartEdit("repair14", report.repair14)
                      }
                    >
                      {report.repair14 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair24}
                      onClick={() =>
                        handlePartEdit("repair24", report.repair24)
                      }
                    >
                      {report.repair24 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair34}
                      onClick={() =>
                        handlePartEdit("repair34", report.repair34)
                      }
                    >
                      {report.repair34 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor4"
                      value={report.labor4}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material4"
                      value={report.material4}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>7 WAY PLUG AND WITING</td>
                  <td>
                    <input
                      name="ok5"
                      type="checkbox"
                      checked={report.ok5}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair15}
                      onClick={() =>
                        handlePartEdit("repair15", report.repair15)
                      }
                    >
                      {report.repair15 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair25}
                      onClick={() =>
                        handlePartEdit("repair25", report.repair25)
                      }
                    >
                      {report.repair25 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair35}
                      onClick={() =>
                        handlePartEdit("repair35", report.repair35)
                      }
                    >
                      {report.repair35 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor5"
                      value={report.labor5}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material5"
                      value={report.material5}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>TIRES, WHEELS AND RIMS</td>
                  <td>
                    <input
                      name="ok6"
                      type="checkbox"
                      checked={report.ok6}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair16}
                      onClick={() =>
                        handlePartEdit("repair16", report.repair16)
                      }
                    >
                      {report.repair16 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair26}
                      onClick={() =>
                        handlePartEdit("repair26", report.repair26)
                      }
                    >
                      {report.repair26 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair36}
                      onClick={() =>
                        handlePartEdit("repair36", report.repair36)
                      }
                    >
                      {report.repair36 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor6"
                      value={report.labor6}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material6"
                      value={report.material6}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>LANDING GEAR, LEG, BRANCES, CRANK, HANDLE</td>
                  <td>
                    <input
                      name="ok7"
                      type="checkbox"
                      checked={report.ok7}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair17}
                      onClick={() =>
                        handlePartEdit("repair17", report.repair17)
                      }
                    >
                      {report.repair17 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair27}
                      onClick={() =>
                        handlePartEdit("repair27", report.repair27)
                      }
                    >
                      {report.repair27 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair37}
                      onClick={() =>
                        handlePartEdit("repair37", report.repair37)
                      }
                    >
                      {report.repair37 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor7"
                      value={report.labor7}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material7"
                      value={report.material7}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>TWISTLOCKS, PIN LOCKS, LATCHES</td>
                  <td>
                    <input
                      name="ok8"
                      type="checkbox"
                      checked={report.ok8}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair18}
                      onClick={() =>
                        handlePartEdit("repair18", report.repair18)
                      }
                    >
                      {report.repair18 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair28}
                      onClick={() =>
                        handlePartEdit("repair28", report.repair28)
                      }
                    >
                      {report.repair28 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair38}
                      onClick={() =>
                        handlePartEdit("repair38", report.repair38)
                      }
                    >
                      {report.repair38 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor8"
                      value={report.labor8}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material8"
                      value={report.material8}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>SUSPENSION U-BOLTS, HANGERS, RADIUS ROD, AXLES</td>
                  <td>
                    <input
                      name="ok9"
                      type="checkbox"
                      checked={report.ok9}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair19}
                      onClick={() =>
                        handlePartEdit("repair19", report.repair19)
                      }
                    >
                      {report.repair19 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair29}
                      onClick={() =>
                        handlePartEdit("repair29", report.repair29)
                      }
                    >
                      {report.repair29 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair39}
                      onClick={() =>
                        handlePartEdit("repair39", report.repair39)
                      }
                    >
                      {report.repair39 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor9"
                      value={report.labor9}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material9"
                      value={report.material9}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    BODY FRAMES, STRUCTURAL, KING PIN/PLATE, CROSS MEMBERS
                  </td>
                  <td>
                    <input
                      name="ok10"
                      type="checkbox"
                      checked={report.ok10}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair110}
                      onClick={() =>
                        handlePartEdit("repair110", report.repair110)
                      }
                    >
                      {report.repair110 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair210}
                      onClick={() =>
                        handlePartEdit("repair210", report.repair210)
                      }
                    >
                      {report.repair210 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair310}
                      onClick={() =>
                        handlePartEdit("repair310", report.repair310)
                      }
                    >
                      {report.repair310 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor10"
                      value={report.labor10}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material10"
                      value={report.material10}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>DECALS / LOGO</td>
                  <td>
                    <input
                      name="ok11"
                      type="checkbox"
                      checked={report.ok11}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair111}
                      onClick={() =>
                        handlePartEdit("repair111", report.repair111)
                      }
                    >
                      {report.repair111 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair211}
                      onClick={() =>
                        handlePartEdit("repair211", report.repair211)
                      }
                    >
                      {report.repair211 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair311}
                      onClick={() =>
                        handlePartEdit("repair311", report.repair311)
                      }
                    >
                      {report.repair311 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor11"
                      value={report.labor11}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material11"
                      value={report.material11}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>LUBRICATION, FITTING, LOOCKS, LANDING GEAR, SLIDER</td>
                  <td>
                    <input
                      name="ok12"
                      type="checkbox"
                      checked={report.ok12}
                      onChange={handleCheck}
                    />
                  </td>
                  <td className={styles.repair}>
                    <button
                      type="button"
                      value={report.repair112}
                      onClick={() =>
                        handlePartEdit("repair112", report.repair112)
                      }
                    >
                      {report.repair112 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair212}
                      onClick={() =>
                        handlePartEdit("repair212", report.repair212)
                      }
                    >
                      {report.repair212 || "Select a service"}
                    </button>
                    <button
                      type="button"
                      value={report.repair312}
                      onClick={() =>
                        handlePartEdit("repair312", report.repair312)
                      }
                    >
                      {report.repair312 || "Select a service"}
                    </button>
                  </td>
                  <td>
                    <textarea
                      name="labor12"
                      value={report.labor12}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="material12"
                      value={report.material12}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <button className="btn btn-success" type="submit">
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
