import { MyThunkAction } from "../../../interfaces/ReduxState";
import { Inspection } from "../../../interfaces/ReportsModels/Inspection";
import { WorkReport } from "../../../interfaces/ReportsModels/Work";
import { Dispatch } from "react";
import axios from "axios";

export const SET_WORK_REPORT = "SET_WORK_REPORT";
export const SET_INSPECTION_REPORT = "SET_INSPECTION_REPORT";
export const SET_REPORTS_ITEM = "SET_REPORTS_ITEM";

export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORTS_ITEMS = "GET_REPORTS_ITEMS";

export const UPDATE_WORK_REPORT = "UPDATE_WORK_REPORT";
export const UPDATE_INSPECTION_REPORT = "UPDATE_INSPECTION_REPORT";

export const DELETE_WORK_REPORT = "DELETE_WORK_REPORT";
export const DELETE_INSPECTION_REPORT = "DELETE_INSPECTION_REPORT";
export const DELETE_REPORTS_ITEM = "DELETE_REPORTS_ITEM";

export function setWorkReport(work: WorkReport): MyThunkAction {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_WORK_REPORT,
      payload: work,
    });
  };
}

export function setInspectionReport(inspection: Inspection): MyThunkAction {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_INSPECTION_REPORT,
      payload: inspection,
    });
  };
}

export function updateWorkReport(report: WorkReport): MyThunkAction {
  return async (dispatch: Dispatch<any>) => {
    // Patch work report
    await axios.patch("/work", report);

    try {
      dispatch({
        type: UPDATE_WORK_REPORT,
        payload: report,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateInspectionReport(report: Inspection): MyThunkAction {
  return async (dispatch: Dispatch<any>) => {
    // Patch work report
    await axios.patch("/inspection", report);

    try {
      dispatch({
        type: UPDATE_INSPECTION_REPORT,
        payload: report,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getReports(): MyThunkAction {
  return async (dispatch: Dispatch<any>) => {
    console.log("nksdfjngsdfonx");
    // Get reports
    const responseWork = await axios.get("/work");
    const responseInspection = await axios.get("/inspection");

    if (!responseWork.data) throw new Error("Error works reports not found");
    if (!responseInspection.data)
      throw new Error("Error inspection reports not found");

    const work = responseWork.data.map((work: WorkReport) => ({
      ...work,
      date: new Date(work.date),
      dateOfRepair: work.dateOfRepair ? new Date(work.dateOfRepair) : null,
    }));
    const inspection = responseInspection.data.map(
      (inspection: Inspection) => ({
        ...inspection,
        date: inspection.date ? new Date(inspection.date) : null,
        yearFMCSA: inspection.yearFMCSA ? new Date(inspection.yearFMCSA) : null,
        FMCSA: inspection.FMCSA ? new Date(inspection.FMCSA) : null,
        yearBIT: inspection.yearBIT ? new Date(inspection.yearBIT) : null,
        BIT: inspection.BIT ? new Date(inspection.BIT) : null,
      })
    );

    try {
      dispatch({
        type: GET_REPORTS,
        payload: {
          work,
          inspection,
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function deleteInspectionReport(reportId: number): MyThunkAction {
  return async (dispatch: Dispatch<any>) => {
    // Delete inspection report
    await axios.delete(`/reports/inspection/${reportId}`);

    try {
      dispatch({
        type: DELETE_INSPECTION_REPORT,
        payload: reportId,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function deleteWorkReport(reportId: number): MyThunkAction {
  return async (dispatch: Dispatch<any>) => {
    // Delete inspection report
    await axios.delete(`/reports/work/${reportId}`);

    try {
      dispatch({
        type: DELETE_WORK_REPORT,
        payload: reportId,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function setItem(listName: string, newValues: string[]): MyThunkAction {
  return async (dispatch: Dispatch<any>) => {
    try {
      // Upload items
      await axios.post("/lists", {
        name: listName,
        values: newValues,
      });

      dispatch({
        type: SET_REPORTS_ITEM,
        payload: {
          listName,
          newValues,
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getLists(): MyThunkAction {
  return async (dispatch: Dispatch<any>) => {
    try {
      // Get items
      const response = await axios.get("/lists");
      if (!response.data.lists) throw new Error("Error to get the list");

      dispatch({
        type: GET_REPORTS_ITEMS,
        payload: {
          ...response.data.lists,
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function deleteItem(listName: string, values: string[]): MyThunkAction {
  return async (dispatch: Dispatch<any>) => {
    try {
      // Delete items
      await axios.post("/lists/delete", {
        name: listName,
        values: values,
      });

      dispatch({
        type: DELETE_REPORTS_ITEM,
        payload: {
          listName,
          values,
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
