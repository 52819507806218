import { useEffect, useState } from "react";
import { Customers as CustomersTS } from "../../../interfaces/Customers";
import useCustomer from "../../../hooks/useCustomers";

import CustomersRow from "./CustomersRow/CustomersRow";
import CustomersForm from "./CustomersForm/CustomersForm";

import styles from "./Customers.module.css";
import searchSvg from "../../../assets/icons/search.svg";

export default function Customers() {
  const customers = useCustomer();
  const [form, setForm] = useState<boolean>(false);
  const [data, setData] = useState<CustomersTS | null>(null);
  const [rows, setRows] = useState<CustomersTS[]>([]);
  const [search, setSearch] = useState<string>("");

  // Get customers
  useEffect(() => {
    if (customers.data.length <= 0) customers.get();
  }, []);

  // Filters customers rows
  useEffect(() => {
    setRows(
      customers.data.filter((customer: CustomersTS) => {
        if (search === "") return true;
        if (
          customer.name?.toLowerCase().includes(search.toLowerCase()) ||
          customer.fullName?.toLowerCase().includes(search.toLowerCase()) ||
          customer.phoneNumber?.toLowerCase().includes(search.toLowerCase()) ||
          customer.email?.toLowerCase().includes(search.toLowerCase()) ||
          customer.shippingAdress
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          customer.billingAdress?.toLowerCase().includes(search.toLowerCase())
        )
          return true;
        return false;
      })
    );
  }, [search, customers.data]);

  // View form and edit customer
  function handleEdit(data: CustomersTS) {
    setData(data);
    handleForm();
  }

  // Alternate form
  function handleForm() {
    setForm(!form);
    if (form) setData(null);
  }

  // Change search bar
  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  function handleSubmit(customer: CustomersTS) {
    data ? customers.update(customer) : customers.set(customer);
  }

  return (
    <div className={styles.dashboard}>
      {form && (
        <CustomersForm
          data={data}
          handleClose={handleForm}
          handleSubmit={handleSubmit}
        />
      )}
      <div className={styles.controls}>
        <div className={styles.searchFilters}>
          <div className={styles.searchBar}>
            <input
              className="form-control"
              placeholder="Search customer"
              value={search}
              onChange={handleSearch}
            />
            <button className="btn btn-outline-primary" type="button">
              <img src={searchSvg} alt="search" />
            </button>
          </div>
        </div>
        <button
          className="btn btn-outline-primary"
          type="button"
          onClick={handleForm}
        >
          + New Customer
        </button>
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={`${styles.row} ${styles.firstRow}`}>
              <th>Name</th>
              <th>Full name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Billing adress</th>
              <th>Shipping adress</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {rows.length <= 0 ? (
              <tr className={styles.emptyRows}>
                <td colSpan={7}>No Customers</td>
              </tr>
            ) : (
              rows.map((customer: CustomersTS) => (
                <CustomersRow
                  key={customer.id}
                  customer={customer}
                  handleEdit={handleEdit}
                  handleDelete={customers.delete}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
